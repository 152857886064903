<template>
  <v-container class="white d-flex flex-column align-center py-14">
    <v-row
      ><v-col><v-icon color="success" dark size="100px">mdi-checkbox-marked-circle</v-icon></v-col>
    </v-row>
    <v-row>
      <v-col><p>Reset hasła powiódł się, teraz możesz się zalogować</p></v-col>
    </v-row>
    <v-row>
      <v-col><primary-button :to="{ name: 'Login' }">Zaloguj się</primary-button></v-col>
    </v-row>
  </v-container>
</template>
<script>
import PrimaryButton from '../../components/buttons/PrimaryButton.vue';
export default {
  name: 'PasswordChanged',
  components: { PrimaryButton },
  data() {
    return {
      isActivated: false,
    };
  },
};
</script>
<style lang="scss" scoped></style>
